/* Add this to your CSS file */

.no-scroll {
  overflow: hidden;
}

.token_name {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #121214;
}

.sell_price_total {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #ef454a;
}

.buy_price_total {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: #20b26c;
  user-select: none;
}

.exchange-header {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.orders::-webkit-scrollbar {
  width: 6px;
  background-color: #161a1e;
}

.orders::-webkit-scrollbar-thumb {
  background-color: #5c5f7d;
}

.orders::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(56 53 53 / 30%);
  background-color: #2e2a4d;
}

.order-book-container,
.p2p-order-book__table {
  display: flex;
  position: relative;
}

/* .order-book-table--open,
.order-book-table--open thead tr th {
  width: 50%;
} */

/* order completed css */

.order-book-trading {
  margin-bottom: 0px;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  table-layout: fixed;
  border-spacing: 0px;
}

.order-book-trading thead tr {
  height: 40px !important;
}

.order-book-trading thead tr th > h6 {
  color: #9d9d9d;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  vertical-align: middle;
  padding: 4px 14px;
}

.order-book-trading tbody tr {
  height: 30px;
  cursor: pointer;
}

.order-book-trading tbody tr:hover {
  background-color: #eeeeee50;
}

/* .order-book-trading tbody tr td.status-dot-cell {
  text-align: center !important;
  padding: 0px;
} */

.order-book-trading tbody tr td {
  font-size: 12px;
  line-height: 12px;
  color: #121214;
  max-width: 0px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 14px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  overflow: hidden;
}

/* order book  CSS*/

.order-book-table {
  /* background-color: rgb(255, 255, 255); */
  margin-bottom: 0px;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  /* table-layout: fixed; */
  border-spacing: 0px;
}

.order-book-table thead tr {
  /* height: 20px; */
  height: 0px !important;
  border: none;
  /* border-top: 0.1px solid rgba(0, 0, 0, 0.1); */
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);
}

.order-book-table thead tr th {
  border-bottom: 0.1px solid rgb(0, 0, 0, 0.1);
}

.order-book-table thead tr th > h6 {
  /* min-height: 22px; */
  color: #81858c;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
  vertical-align: middle;
  padding: 4px 14px;
}

.order-book-table tbody tr {
  height: 30px;
  cursor: pointer;
  /* border-bottom: 1px solid transparent; */
}

/* .order-book-table tbody.buy tr td {
  text-align: right;
} */
/* .order-book-table tbody tr td.status-dot-cell {
  text-align: center !important;
  padding: 0px;
} */
.order-book-table tbody tr td {
  font-size: 12px;
  line-height: 12px;
  color: #121214;
  /* letter-spacing: 0.4px; */
  max-width: 0px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 14px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  overflow: hidden;
}

.order-book-table tbody tr .filler {
  position: absolute;
  height: 24px;
}

.order-book-table tbody.buy tr .filler {
  right: 0;
  left: auto;
  background: #24ae8f;
  opacity: 0.12;
}

.order-book-table--right {
  right: 0px;
}

.order-book-table tbody.sell tr .filler {
  right: 0;
  left: auto;
  background: #ef454a;
  opacity: 0.12;
}

.currency-market-wrapper {
  background-color: #fff;
  margin-bottom: 1px;
}

.unfavourite_icon {
  color: #7e7e7e;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
}

.unfavourite_icon:hover {
  transform: scale(1.5);
}

.favourite_icon {
  color: #ffc107;
  font-size: 14px;
}

.exchange_styled {
  background-color: #f4f6f7;
  padding-top: 4.24em;
  /* display: flex; */
  font-size: 12px;
}

.header_bg {
  background-color: var(--black-color-600);
  /* border-bottom: 1px solid #eee; */
  /* padding-top: 5px !important; */
  /* padding-bottom: 0!important; */
}

.left_layout {
  min-width: 730px;
  display: flex;
  flex-grow: 1;
  margin-right: 4px;
  flex-direction: column;
}

.right_layout {
  display: flex;
  flex-direction: column;
}

.exchange_styled .card {
  /* border: 1px solid rgb(188 188 188 / 18%) !important; */
  border: 1px solid #f4f6f7;
}

.exchange_styled .card-header {
  background-color: #fff;
  border-bottom: none;
  color: #000;
  font-size: 12px;
}

.exchange_input_grp .input-group-text {
  font-size: 13px;
  background-color: #f5f6f6 !important;
  color: #a6a6a6 !important;
  border: 1px solid #f5f6f6;
  /* border-radius: 0.175rem!important; */
}

.exchange_input_grp.input-group input {
  background-color: #f5f6f6;
  font-size: 13px;
  text-align: right;
  color: #000d1d;
  border-color: #f5f6f6;
  height: 36px;
}

.exchange_input_grp.input-group .form-control:focus {
  /* border-color: #eee; */
  box-shadow: none !important;
}

.buy_sell_amount_picker {
  color: rgb(129 129 129);
  background-color: rgba(1, 8, 30, 0.04);
  font-size: 12px;
  line-height: 2.5rem;
  height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  width: calc(25% - 5px) !important;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
}

.exchange_tbl.table {
  font-size: 12px;
  margin-bottom: 0;
  /* table-layout: fixed; */
  border-spacing: 0px !important;
}

.exchange_tbl.table > tbody > tr > th,
.exchange_tbl.table > tbody > tr td {
  border-top: 1px solid transparent;
}

.exchange_tbl tr {
  height: 25px !important;
}

.table_striped tbody tr:nth-child(odd) {
  background-color: #ececec;
}

.table_on_hover .order-book-table tbody tr:hover,
.table_on_hover .order-book-table tbody tr:hover ~ tr {
  background-color: #f5f5f6 !important;
}

.btn-danger {
  background-color: #fa3f51 !important;
  border: 1px solid #fa3f51 !important;
}

.btn-success {
  background-color: rgb(1, 188, 141) !important;
  border-color: #49c774 !important;
}

.exchange_styled .footer_div {
  display: none;
}

.value-item {
  display: flex;
  height: 40px;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 5px 10px;
  flex: 0 0 calc(50% - 10px);
  background-color: #eee;
  align-items: center;
  cursor: pointer;
}

.value-item-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.value-item-label p {
  margin-bottom: 0;
}

.sell-price-bg {
  background: linear-gradient(
    90deg,
    rgba(254, 69, 57, 0.2) 0,
    rgba(16, 16, 20, 0.04)
  );
}

.buy-price-bg {
  background: linear-gradient(
    90deg,
    rgba(32, 178, 108, 0.25) 0,
    rgba(32, 178, 108, 0.05)
  );
}

.gray_btn {
  /* width: 98%; */
  height: 32px;
  position: relative;
  background-color: #2323231c;
  color: #000;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 12px 5px 7px;
}

.trade_btn {
  width: 50%;
  height: 32px;
  position: relative;
  background-color: var(--main-color);
  color: #000;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.tooltip_text {
  border-color: #ccc;
  border-bottom: 1px dashed #ccc;
  display: inline-block;
  margin-bottom: 5px;
  color: #81858c;
}

.tooltip_content {
  border-color: #ccc;
  display: inline-block;
  margin-bottom: 5px;
  color: #81858c;
}

.eth-market {
  padding: 20px 20px 0 20px;
  background: #fff;
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2) !important; */
}

.hide-currency-market {
  display: none;
  z-index: 950;
  position: absolute;
  width: 40rem;
  height: calc(100vh-5rem);
  top: 4.5rem;
  left: 1px;
  /* background: #fff; */

  /* border-radius: 5px; */
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important; */
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2) !important; */
}

/* .hide-currency-market-inner {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: #F5F5F7;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #272A2E;
} */

.currency_header:hover .hide-currency-market {
  display: block;
  bottom: 0;
}

.arrow_down {
  position: relative;
  top: -2px;
  border: solid #666;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  font-size: 9px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin-left: 15px;
}

.currency_header:hover .arrow_down {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  top: 4px;
  position: relative;
  border: solid #2196f3;
  border-width: 0 1px 1px 0;
}

.sidebar-overflow {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}

/* .exchange-pairs {
  border-radius: 3px;
  background-color: #272A2E;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: #F5F5F7;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
} */

.tooltip_box {
  /* box-shadow: 0 3px 4px 0 rgba(1, 8, 30, 0.16),
    0 1px 16px 0 rgba(1, 8, 30, 0.08); */
  background-color: #f5f5f5;
  font-size: 14px;
  color: #01081e;
  padding: 5px 8px;
  width: 200px;
  word-wrap: break-word;
  font-weight: 400;
  z-index: 9999999;
  border-radius: 2px;
  font-size: 12px;
  /* text-align: center; */
  /* display: flex; */
  /* justify-content: space-between; */
}

.left_col {
  width: 140px;
}

.left_col .nav-pills .nav-link.active {
  color: #000 !important;
  background: #f5f6f6 !important;
  border-bottom: 0 !important;
}

.left_col .nav-pills .nav-link {
  font-size: 14px;
}

.exchange_main {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  /* position: relative; */
  height: 100%;
  width: 100%;
}

.exchange_row {
  display: flex;
  position: relative;
  height: calc(100% - 48px);
  width: 100%;
}

.exchange_col_one {
  display: flex;
  flex-direction: column;
  min-width: 324px;
  max-width: 324px;
  height: 100%;
  z-index: 2;
}

.exchange_col_two {
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 624px);
  max-width: calc(100% - 624px);
}

.exchange_col_three {
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  min-width: 300px;
}

.parent {
  position: relative;
  width: 500px;
  height: 300px;
  background-color: lightgray;
  padding: 20px;
}

.overlay {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background-color: white;
  z-index: 9999;
}

.overlay-content {
  padding: 20px;
}

.buysell_tabs .nav-pills .nav-link {
  align-items: center;
  background-color: var(--light-global-color);
  border: 1px solid var(--light-global-color);
  border-radius: 2px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 35px;
  justify-content: center;
  margin-right: 10px;
  margin-top: 6px;
  transform: skewX(-20deg);
  outline: 0 !important;
  box-shadow: none !important;
}

.buysell_tabs .nav-pills .nav-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.buysell_tabs .nav-pills .buy_tab:after,
.buysell_tabs .nav-pills .sell_tab:after {
  background-color: inherit;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  -ms-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
  width: 15px;
}

.buysell_tabs .nav-pills .link-text {
  -ms-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.buysell_tabs .nav-pills .buy_tab:after {
  border-radius: 2px;
  left: -7px;
}

.buysell_tabs .nav-pills .sell_tab:after {
  border-radius: 2px;
  right: -7px;
}

.buysell_tabs .nav-pills .buy_tab.active {
  background-color: #00bd84 !important;
  color: #fff !important;
}

.buysell_tabs .nav-pills .sell_tab.active {
  background-color: #f24e53 !important;
  color: #fff !important;
}

select.custom-select:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.full_width_dropdown {
  position: fixed;
  top: 8rem;
  left: 0;
  height: 90vh;
  width: 100%;
  z-index: 4;
}

.inner_wrapper {
  display: flex;
  height: 100%;
}

.left_wrapper {
  height: 100%;
  min-width: 480px;
  max-width: 480px;
  background-color: #fff;
  border-right: 1px solid #ccc;
}

.inner_wrapper .right_wrapper {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  transition: 0.25s ease-in-out;
  background-color: #fefefe57;
  flex: 1 1 100%;
}

.filter_btn {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  font-size: 1rem;
  height: 28px;
  margin-right: 20px;
  padding: 8px 8px 10px;
  position: relative;
  border: 0;
  background-color: transparent;
  border-radius: 2px;
}

.filter_btn.active {
  position: relative;
}

.filter_btn.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 30px;
  border-bottom: 3px solid var(--main-color);
  /* z-index: -1; */
}

.navbar_filter .filter_btn {
  color: #fff;
}

.navbar_filter .filter_btn.active {
  color: var(--main-color);
  font-weight: 600;
}

/* order-book-dropdown */

.order-drpdown-btn {
  border: 0;
  outline: 0;
  box-shadow: none;
  font-size: 13px;
}

.order-drpdown-btn.dropdown:hover .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.order-book-dropdown {
  min-width: 80px;
  border: 0 !important;
}

.order-book-dropdown .dropdown-item {
  font-size: 12px;
  padding: 10px 5px !important;
  text-align: center;
}

.order-book-dropdown .dropdown-item.active,
.order-book-dropdown .dropdown-item:active {
  background-color: #f4f4f4;
  color: #000;
}

.order-book-dropdown .dropdown-item:hover {
  background-color: #f4f4f4 !important;
  color: #000;
}

.sorting_img {
  width: 20px;
  height: 20px;
  opacity: 0.35;
  transform: scale(0.8);
}

.sorting_img.active {
  opacity: 1;
}

.buysell_without_login {
  align-content: center;
  background-color: rgb(255 255 255 / 78%);
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0.95;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.buysell_without_login .message_part {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.71;
  margin-bottom: 37px;
  margin-top: 104px;
  text-align: center;
}

.buysell_login_btn {
  background-color: #2894f9;
  border: none;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  height: 43px;
  margin-bottom: 15px;
  text-decoration: none;
  width: 171px;
}

.mp-exide-form.form-control:focus {
  /* outline: 0!important; */
  border-color: #ced4da;
  box-shadow: none !important;
}

.page_minheight {
  min-height: 95vh;
}

.placeholder_curency_market {
  min-height: 70px;
}

.chart_wrapper {
  min-height: 450px;
}

.order_history {
  min-height: 308px;
}

.order_book {
  min-height: 760px;
}

.trade_history {
  min-height: 385px;
}

.buy_sell {
  min-height: 370px;
}

.placeholder {
  background-color: #ddd;
}

/* 
.empty-card {
  width: 280px;
  height: 380px;
  background-color: red;
} */

.custom-select {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #f3f3f3;
}

#open-order .btn {
  border-radius: 5px;
}

html.theme-dark .group-wWM3zP_M {
  background-color: #0e0e0e !important;
}

html.theme-dark .group-2JyOhh7Z {
  background-color: red !important;
}

#drawing-toolbar div + div + div + div + div {
  background-color: red !important;
}

.asset_dropdown_wrapper {
  position: relative;
}

.asset_dropdown_menu {
  width: 100%;
  left: -15px !important;
  top: 12px !important;
  padding: 20px;
  position: absolute !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0;
  box-shadow: 2px 3px 6px #ccc;
}

.asset_dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: auto;
}

.asset_dropdown.dropdown-toggle::after {
  display: none !important;
}

ul.bullet_style {
  margin: 0;
  padding: 0;
}

ul.bullet_style li {
  margin-bottom: 15px;
}

.header-card {
  border-radius: 8px;
  background-color: var(--black-color-300);
  padding: 10px;
}

.header_asset_content {
  border-radius: 0 0 8px 8px;
  width: 266px;
}

.asset-dropdown-header {
  color: #ccc;
  font-size: 12px;
  padding: 10px 0 10px 10px;
}

.header_asset_content {
  color: var(--white-color);
}

.header_asset_content .dropdown-item:focus,
.header_asset_content .dropdown-item:hover {
  color: var(--light-gray-color);
  background-color: var(--black-color-300);
}

.header_notice_content {
  border-radius: 0 0 8px 8px;
  width: 410px;
}

#notification-list {
  width: 350px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}

.activity-item .icon_bg {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: var(--darkgray-color-700);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 27px;
  color: var(--dark-gray-color-300);
  font-weight: 600;
  flex-shrink: 0;
}

#notification-list .activity-item {
  padding: 10px;
  display: flex;
  column-gap: 10px;
  border-radius: 0.375rem;
  /* border-top: 1px solid #f5f5f5; */
  cursor: pointer;
}

#notification-list .activity-item:hover {
  background-color: var(--black-color-300);
}

#notification-list .activity {
  overflow: auto;
}

#notification-list .activity-item a {
  font-weight: 600;
}

#notification-list .activity span {
  display: block;
  color: #999;
  font-size: 11px;
  line-height: 16px;
}

#notification-list .subdecription {
  color: #888;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-notification {
  padding: 10px 5px;
  text-align: center;
}

.noty-manager-bubble {
  align-items: center;
  background: #fe4539;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  line-height: 12px;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  color: #fff;
}

.noty-bubble {
  align-items: center;
  background: #fe4539;
  border-radius: 50%;
  /* box-sizing: border-box; */
  display: flex;
  font-size: 12px;
  height: 16px;
  justify-content: center;
  line-height: 12px;
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  color: #fff;
}

.theme-setting-box {
  background-color: "red";
  /* padding-bottom: 20px; */
  /* margin: 20px 0; */
  /* border-bottom: 1px solid rgba(243, 245, 247, .8); */
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.theme-setting-box .switch {
  border-radius: 8px;
  height: 32px;
  width: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 2px;
  box-sizing: border-box;
  opacity: 1;
  background-color: #eee;
}

.theme-setting-box .switch .item {
  border-radius: 4px;
  width: 32px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #adadad;
}

.theme-setting-box .switch .item.active {
  background-color: #fff;
  color: #000;
}

.user_avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  flex-shrink: 0;
}

.verification_card:before {
  content: "";
  display: block;
  position: absolute;
  /* right: 1rem; */
  top: -8px;
  left: 12px;
  border-bottom: 8px solid #dfeef9;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.verification_card {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  margin: 10px auto 10px;
  background: #dfeef9;
  color: #000;
  position: relative;
  font-size: 13px;
}

.userprofile_sidbar_nav {
  padding: 10px 10px 10px 0;
  display: block;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  position: relative;
  color: var(--dark-gray-color);
}

.userprofile_sidbar_nav:hover {
  color: var(--white-color);
}

.bg-badge {
  background-color: #dfeef9;
  color: #000;
  font-weight: 600;
}

.drawer {
  position: fixed;
  right: 0;
  width: 310px;
  background-color: var(--black-color-500);
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 5;
  bottom: 0;
  top: 3rem;
}

.drawer-menu-container {
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.drawer-menu-container::-webkit-scrollbar {
  width: 3px;
}

.drawer-menu-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.drawer-menu-container::-webkit-scrollbar-thumb {
  background-color: #eee !important;
  border-radius: 5px;
}

.header_coin_scrollbar::-webkit-scrollbar {
  width: 3px;
}

.header_coin_scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.header_coin_scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray-color-600) !important;
  border-radius: 5px;
}

.logout-wrapper {
  /* box-shadow: 1px -6px 10px 3px #666;
  position: absolute;
  bottom: 0;
  z-index: 8; */
}

.email_text_width {
  width: 200px;
}

.exchange_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  /* margin-bottom: 35px; */
}

.exchange_container {
  display: flex;
  position: relative;
  height: calc(100% - 48px);
  width: 100%;
}

.left-column {
  margin: 1px;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  min-width: calc(100% - 304px);
  /* max-width: calc(100% - 304px); */
}

.right-column {
  margin: 1px 1px 2px 2px;
  display: flex;
  flex-direction: column;
  min-width: 298px;
  /* max-width: 298px; */
  /* border-right: 1px solid #2d2d3d; */
  /* height: calc(100% - 8rem); */
  z-index: 2;
  background-color: #fff;
}

.graph-column {
  scrollbar-width: none;
  margin: 1px;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  min-width: calc(100% - 322px);
  /* max-width: calc(100% - 322px); */
}

.trade-column {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 1px;
  display: flex;
  flex-direction: column;
  min-width: 319px;
  /* max-width: 319px; */
  /* border-right: 1px solid #2d2d3d; */
  height: 100%;
  z-index: 2;
}

.exchange_inner_container {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  .exchange_inner_container {
    display: grid !important;
  }
  .order-book-trading {
    table-layout: auto !important;
  }
}

.exchange_col {
  /* flex: 1; */
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
}

.exchange-col:last-child {
  margin-right: 0;
}

.trade_height {
  min-height: 554px;
  max-height: 554px;
  overflow: auto;
}

.order_height {
  min-height: 260px;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

.buysell_height {
  /* min-height: calc(100vh - 16.6rem);
  max-height: calc(100vh - 16.6rem); */
  overflow: auto;
}

.min_height_order_book {
  min-height: 514px;
  max-height: 514px;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 317px;
}

@media (min-width: 992px) {
  .min_height_order_book {
    width: 300px;
  }
}

.buy_minheight_1 {
  min-height: 224px;
  max-height: 224px;
  overflow-y: auto;
  overflow-x: hidden;
}

.buy_minheight_2 {
  max-height: 260px;
  overflow-y: auto;
  min-height: 260px;
  overflow-x: hidden;
}

.filtered_by_sell_height {
  min-height: 685px;
  max-height: 685px;
  overflow: auto;
}

.sell_minheight {
  height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sticky_thead {
  position: sticky;
  background-color: #ffff;
  border-bottom: 1px solid #fff;
  z-index: 1;
  top: 0;
  width: 100%;
}

.order-book-table thead.sticky_thead tr th {
  border-top: none;
  border-bottom: 1px solid transparent;
}

.table_center_div {
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  /* border-top: 1px solid rgba(1, 8, 30, 0.04);
  border-bottom: 1px solid rgba(1, 8, 30, 0.04); */
  background-color: #f8f8f8;
}

.text-green {
  color: #4caf50 !important;
}

.text-red {
  color: #dc5656 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.custom_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.exchange-footer {
  background-color: #f9f9f9;
  padding: 8px 0;
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%; */
  color: #666;
  text-align: center;
  z-index: 2;
}

/* Responsive styles */
@media (max-width: 768px) {
  .exchange_container {
    flex-direction: column;
  }

  .left-column,
  .right-column {
    flex: none;
    width: 100%;
  }

  /* Reset width for smaller screens */
  .exchange_col {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .w3_megamenu .dropdown-menu {
    border: 0;
  }

  .navbar_table_scroll .table {
    --bs-table-hover-bg: var(--darkgray-color-700) !important;
    --bs-table-color: var(--white-color) !important;
  }
  .navbar_table_scroll .table h6 {
    color: var(--white-color);
  }

  .navbar_table_scroll .img-container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .navbar_table_scroll .img-container img {
    border-radius: 50%;
  }

  /* nested menu start */
  .w3_megamenu .nested_menuwrapper:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }

  .w3_megamenu .nested_menuwrapper .dropdown-menu {
    display: none;
    position: absolute;
    width: 280px;
    /* max-height: 350px;
    min-height: 400px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0 10px 60px;
    z-index: 0;
    background: var(--black-color-400);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .w3_megamenu .dropdown-submenu {
    margin: 0 10px;
    border-radius: 5px;
  }

  .nested_menuwrapper li,
  li.nested_menuwrapper ul li.nav-item a.nav-link {
    border-radius: 5px;
  }

  .w3_megamenu .dropdown-submenu:hover,
  .nested_menuwrapper ul li.nav-item a.nav-link:hover {
    background-color: var(--black-color-300);
  }

  .w3_megamenu .multilevel_menu.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
    margin-left: -5px;
    border-bottom-left-radius: 0;
    display: none;
  }

  .w3_megamenu .multilevel_menu.dropdown-submenu:hover > .dropdown-menu {
    display: block;
    background: hsla(0, 0%, 100%, 0.06);
    color: var(--white-color);
    font-weight: 600;
    min-width: 310px;
    max-height: 350px;
    min-height: 400px;
    padding: 0 12px;
    /* overflow-y: auto; */
    white-space: nowrap;
    background: var(--black-color-300);
    /* border-radius: 0px 16px 16px 0px; */
    box-shadow: rgba(0, 0, 0, 0.05) 35px 20px 45px 0px,
      rgba(0, 0, 0, 0.05) 0px 15px 15px -8px;
    z-index: 1;
  }

  .w3_megamenu .multilevel_menu.dropdown-submenu > a:after {
    display: none;
    /* content: " ";
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  position: absolute;
  right: 20px; */
  }

  .w3_megamenu .multilevel_menu.dropdown-submenu:hover > a:after {
    border-left-color: #cccccc;
  }
}

/* nested menu end */

.carousel-control {
  width: 30px;
  height: 30px;
  top: -35px;
}

.left.carousel-control {
  right: 30px;
  left: inherit;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  font-size: 12px;
  background-color: #fff;
  line-height: 30px;
  text-shadow: none;
  color: #333;
  border: 1px solid #ddd;
}

.margin-mode {
  position: relative;
  background-color: #f3f5f7;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
  height: 32px;
  box-sizing: border-box;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ============ Nav Pills tabs ============*/

.custom_tabs .nav-pills .nav-link {
  color: #333 !important;
  background: #f3f5f7 !important;
}
.custom_tabs > .nav-pills .nav-link.active,
.custom_tabs > .nav-pills .show > .nav-link {
  background: #20b26c !important;
  color: #fff !important;
}

.custom_tabs {
  background-color: #f3f5f7;
}
